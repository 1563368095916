<template>
  <div>
    <!-- dialog -->
    <van-dialog
      :before-close="setDfBefore"
      v-model="setDfModal"
      title="设定自动接单"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="self.self_df_min"
          name="最低"
          label="最低"
          placeholder="最低"
          type="number"
          :rules="[{ required: true, message: '请填写最低' }]"
        />
        <van-field
          v-model="self.self_df_max"
          name="最高"
          label="最高"
          type="number"
          placeholder="最高"
          :rules="[{ required: true, message: '请填写最高' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="editPasswordBefore"
      v-model="editPasswordModal"
      title="变更密码"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="password"
          name="旧密码"
          label="旧密码"
          placeholder="旧密码"
          :rules="[{ required: true, message: '请填写旧密码' }]"
        />
        <van-field
          v-model="newPassword"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      :before-close="editWithDrawPasswordBefore"
      v-model="editWithDrawPasswordModal"
      title="变更密码"
      show-cancel-button
    >
      <van-form>
        <van-field
          v-model="password"
          name="旧密码"
          label="旧密码"
          placeholder="旧密码"
          :rules="[{ required: true, message: '请填写旧密码' }]"
        />
        <van-field
          v-model="newPassword"
          name="密码"
          label="密码"
          placeholder="密码"
          :rules="[{ required: true, message: '请填写密码' }]"
        />
      </van-form>
    </van-dialog>
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar
        title=""
        @click-left="$router.push('/selfData')"
        left-text="返回"
        left-arrow
      >
        <!-- <template #right> 欢迎您 {{ $store.state.selfData.name }} </template> -->
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <van-cell-group style="margin-top: 15px" inset>
      <van-cell
        title="变更密码"
        @click="
          editPasswordModal = true;
          newPassword = '';
          password = '';
        "
        is-link
        value="设定"
      />
      <van-cell
        title="变更提现密码"
        @click="
          editWithDrawPasswordModal = true;
          newPassword = '';
          password = '';
        "
        is-link
        value="设定"
      />
      <van-cell
        @click="setDf"
        :title="'设定自动接单(' + $returnBuySel('出货') + ')'"
        is-link
        :value="(selfData.self_df_min || 0) + '~' + (selfData.self_df_max || 0)"
      />
    </van-cell-group>
    <!-- <foot /> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
// import foot from "../../components/footer";
import { Toast } from "vant";
export default {
  // components: { foot },
  data() {
    return {
      editPasswordModal: false,
      editWithDrawPasswordModal: false,
      newPassword: "",
      password: "",
      show: false,
      setDfModal: false,
      self: {},
    };
  },
  computed: {
    ...mapState([
      "selfData",
      // ...
    ]),
  },
  methods: {
    setDfBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .post(`/mobile/personalSetting `, {
            self_df_max: this.self.self_df_max,
            self_df_min: this.self.self_df_min,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.setDfModal = false;
              this.$store.commit("setSelf", res.data.data);
              // this.$store.dispatch("getSelf");
              Toast.success("成功");
            }
          });
        done(false);
      } else {
        this.editPasswordModal = false;
        done();
        return;
      }
    },
    setDf() {
      this.setDfModal = true;
      this.self = JSON.parse(JSON.stringify(this.selfData));
    },
    editPasswordBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .put(`/mobile/password `, {
            new_password: this.newPassword,
            old_password: this.password,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.editPasswordModal = false;
              this.$successMeg();
            }
          });
        done(false);
      } else {
        this.editPasswordModal = false;
        done();
        return;
      }
    },
    editWithDrawPasswordBefore(method, done) {
      if (method == "confirm") {
        this.$http
          .put(`/mobile/password `, {
            new_withdraw_password: this.newPassword,
            old_withdraw_password: this.password,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$successMeg();
              this.editWithDrawPasswordModal = false;
            }
          });
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
  },
  created() {
    this.$store.dispatch("getSelf");
  },
};
</script>

<style lang="scss" scoped></style>
